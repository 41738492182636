import * as React from 'react';
import Layout from '../components/Layout';
import { LegalContent } from '../components/StyleGuide/styles';
import Head from '../components/Head';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Head />
      <LegalContent>
        <h1>Zero One Services Limited Privacy Policy</h1>
        <p>
          This website, https://zero-1.co (the &ldquo;Website&ldquo;), is owned
          and operated by Zero One Services Limited having its registered office
          at Unit 30 Benyon Wharf, 295 Kingsland Road, London, E8 4DQ, UK.
        </p>
        <p>
          For the purposes of Data Protection Legislation (as defined below)
          Zero One Services Limited is the data controller. References to
          &ldquo;Zero 1&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; or
          &ldquo;our&rdquo; are references to Zero One Services Limited.
        </p>
        <p>We take the privacy of your information seriously.</p>
        <p>
          This privacy policy explains how we collect and use your personal
          information in connection with the provision of services including:
        </p>
        <ul>
          <li>
            <span>
              through your use of our Website (as defined below) and any or any
              Zero 1 application that we make available from time to time or by
              contacting us via any means including through our Website live
              chat, Facebook, by email or phone; or
            </span>
          </li>
          <li>
            <span>
              by interacting with our content and/or services including by
              applying to be one of our employees.
            </span>
          </li>
        </ul>
        <p>
          This policy also applies to information held about suppliers and
          possible future suppliers, contacts and all other people we hold
          information about.
        </p>
        <p>This policy does not apply to our employees.</p>
        <p>
          References to &ldquo;you&rdquo; are references to any natural person
          excluding any of our employees data but including any person accessing
          this Website.
        </p>
        <p>Please read this privacy policy carefully.</p>
        <ol>
          <li>
            <h2>Definitions and interpretation</h2>
            <ol>
              <li>
                <p>
                  In this privacy policy, the following definitions are used:
                </p>
              </li>
            </ol>
            <p>&ldquo;Data Protection Legislation&rdquo;</p>
            <p>
              the EU General Data Protection Regulation 2016/679 and the UK Data
              Protection Act 2018 together with all other applicable legislation
              relating to privacy or data protection and including any statute
              or statutory provision which amends, extends, consolidates or
              replaces the same. The terms &ldquo;personal data&rdquo;,
              &ldquo;data subject&rdquo;, &ldquo;controller&rdquo;,
              &ldquo;processor&rdquo; ,&ldquo;process&rdquo; (and its
              derivatives) and &ldquo;special categories of personal data&rdquo;
              shall have the meanings given to them in the Data Protection
              Legislation;
            </p>
            <p>&ldquo;Cookies&rdquo;</p>
            <p>
              a small text file placed on your computer by this Website when you
              visit certain parts of the Website and/or when you use certain
              features of the Website. Details of the cookies used by this
              Website are set out in the clause below (Cookies);
            </p>
            <p>&ldquo;UK and EU Cookie Law&rdquo;</p>
            <p>
              the Privacy and Electronic Communications (EC Directive)
              Regulation 2003 as amended;
            </p>
          </li>
          <li>
            <h2>What information we collect</h2>
            <ol>
              <li>
                <p>
                  Personal data means any information about an individual from
                  which that person can be identified. It does not include data
                  where the identity has been removed (anonymous data).
                </p>
                <p>
                  We may collect and process different kinds of personal data
                  about you which we have grouped together as follows:
                </p>
                <p>
                  Identity &amp; Contact Data includes first name, last name,
                  username or similar identifier, title, gender, email address
                  and telephone number.
                </p>
                <p>
                  Technical &amp; Usage Data includes internet protocol (IP)
                  address, browser type and version, time zone setting and
                  location, browser plug-in types and versions, operating system
                  and platform, and other technology on the devices you use to
                  access this website. We also collect information about how you
                  use our website.
                </p>
                <p>
                  Marketing &amp; Communications Data includes your preferences
                  in receiving marketing from us and our third parties and your
                  communication preferences.
                </p>
                <p>
                  We do not collect any Special Categories of Personal Data
                  about you (this includes details about your race or ethnicity,
                  religious or philosophical beliefs, sex life, sexual
                  orientation, political opinions, trade union membership,
                  information about your health, and genetic and biometric
                  data). Nor do we collect any information about criminal
                  convictions and offences.
                </p>
              </li>
              <li>
                <p>IF YOU FAIL TO PROVIDE PERSONAL DATA&nbsp;&nbsp;</p>
              </li>
              <li>
                <p>
                  Where we need to collect personal data by law, or under the
                  terms of a contract we have with you, and you fail to provide
                  that data, we may not be able to perform the contract we have
                  or are trying to enter into with you.{' '}
                </p>
              </li>
              <li>
                <p>From applicants to Zero 1:</p>
                <p>
                  We may collect the following types of personal data about you
                  in connection with applying to be an employee for Zero One
                  Services Limited:
                </p>
                <p>(a) your name and date of birth;</p>
                <p>
                  (b) your home address and contact details (including your
                  mobile telephone number and email address),
                </p>
                <p>
                  (c) your educational (including what training courses you have
                  completed) and professional qualifications, your right to
                  work, visa status, employment status; and
                </p>
                <p>
                  &nbsp;(g) any other information about you that you disclose to
                  us during the application process including your CV; and
                </p>
                <p>(h) details of how you heard about us.</p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Purposes of processing and use of your personal information</h2>
            <ol>
              <li>
                <p>
                  Any or all of the above types of personal data may be required
                  by us from time to time in order to provide you with the best
                  possible services and experience when using our Website or
                  otherwise when providing our Services.
                </p>
              </li>
              <li>
                <p>
                  Generally, personal data may be used by us for the following
                  reasons:
                </p>
              </li>
            </ol>
            <p>(a) internal record keeping;</p>
            <p>
              (b) consider your application where you have applied for a
              position with us
            </p>
            <p>(c) improvement of our products/services;</p>
            <p>(d) data analytics;</p>
            <p>
              (e) carry out our obligations arising from any agreements entered
              into between you and us;
            </p>
            <p>(f) communicate with you;</p>
            <p>
              (g) administer the Website and for internal operations, including
              troubleshooting, data analysis, testing, research, statistical and
              survey purposes;
            </p>
            <p>
              (h) transmission by email of promotional materials that may be of
              interest to you (where we have the right to do so);
            </p>
            <p>
              in each case, in accordance with this privacy policy and Data
              Protection Legislation.
            </p>
          </li>
          <li>
            <h2>Legal basis for processing</h2>
            <ol>
              <li>
                <p>
                  Our processing of your personal data is necessary: (i) for the
                  performance of contracts to which you will be a party and in
                  order to take steps at your request prior to you entering into
                  those contracts; (ii) where required by a legal or regulatory
                  obligation; and (iii) for the purposes of legitimate interests
                  pursued by us (and where this is not outweighed by your
                  rights).
                </p>
              </li>
              <li>
                <p>
                  If you completed the Data Protection Consent Form appended to
                  the Zero 1 Terms and Conditions you will have provided consent
                  in accordance with Data Protection Legislation to the
                  processing by Zero 1 of your special categories of personal
                  data.
                </p>
              </li>
              <li>
                <p>
                  Where our processing is based on the legitimate interest
                  grounds described above, those legitimate interests are: (i)
                  collecting personal information to provide you with a smooth
                  and efficient customer experience; (ii) running our business;
                  (iii) to provide the products and services you have requested;
                  (iv) to prevent fraud; and (v) for the development of our own
                  marketing (not for the purpose of sending unsolicited emails),
                  research and service.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Disclosure of your personal information</h2>
            <ol>
              <li>
                <p>
                  Except as expressly set out in this privacy policy we do not
                  share, sell, or lease personal information about you to any
                  third parties for their marketing use.
                </p>
              </li>
              <li>
                <p>
                  However, we share your information with the following
                  categories of companies as an essential part of being able to
                  provide our products and services to you, as set out in this
                  privacy policy:
                </p>
                <p>
                  (a) Professional service providers, such as marketing
                  agencies, advertising partners and website hosts who help us
                  run our business;
                </p>
                <p>
                  (b) Credit reference agencies, law enforcement and fraud
                  prevention agencies, so that we can help tackle fraud and
                  otherwise conduct background checks;
                </p>
                <p>
                  (c) Companies approved by you, such as social media sites (if
                  you choose to link your accounts to us);
                </p>
                <p>
                  (d) Other business entities should we plan to merge with or be
                  acquired by that business entity, or if we undergo a
                  re-organisation with that entity.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Transferring data outside of the EEA</h2>
            <ol>
              <li>
                <p>
                  Your personal information may be transferred to, and stored
                  at, a destination outside the European Economic Area
                  (&ldquo;EEA&ldquo;) and may also be processed by staff
                  operating outside the EEA who work for us, or for one of our
                  service providers.
                </p>
              </li>
              <li>
                <p>
                  When we, or our permitted third parties, transfer your
                  information outside the European Economic Area, we or they
                  will impose contractual obligations on the recipients of that
                  data to protect your information to the standard required by
                  the Data Protection Legislation. We or they may also require
                  the recipient to subscribe to international frameworks
                  intended to enable secure data sharing. In the case of
                  transfers by us, we may also transfer your information where:
                  (i) the transfer is to a country deemed by the European
                  Commission to provide adequate protection of your information;
                  or (ii) where you have consented to the transfer.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>How long will we keep your information?</h2>
            <ol>
              <li>
                <p>
                  We will only keep the information we collect about you for as
                  long as required for the purposes set out above or as required
                  to comply with any legal obligations to which we are subject.
                  This will involve us reviewing our files to check that
                  information is accurate, up-to-date and still required.
                </p>
              </li>
              <li>
                <p>
                  Where we are permitted to send you direct marketing
                  communications we may retain your contact information
                  necessary for this purpose, (provided that you have consented
                  to receiving them) until you unsubscribe from receiving the
                  same from us.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Liquidation or Dissolution</h2>
            <ol>
              <li>
                <p>
                  If, for whatever reason, Zero 1 was no longer operational
                  either by dissolution or in liquidation, we will take steps to
                  seek to place personal data in escrow or ring-fence the data,
                  so as to seek to ensure it&rsquo;s safe for the purpose of any
                  administrative process.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Your rights</h2>
            <ol>
              <li>
                <p>
                  You have a number of rights concerning the way that we use
                  personal data. At any time, you have the right to:
                </p>
                <p>
                  (a) be informed about the processing of your personal data
                  (i.e. for what purposes, what types, to what recipients it is
                  disclosed, storage periods, any third party sources from whom
                  it was obtained, confirmation of whether we undertake
                  automated decision-making, including profiling, and the logic,
                  significance and envisaged consequences);
                </p>
                <p>
                  (b) request access to or a copy of any personal data we hold
                  about you;
                </p>
                <p>
                  (c) request the rectification of your personal data, if you
                  consider that it is inaccurate;
                </p>
                <p>
                  (d) request the erasure of your personal data, if you consider
                  that we do not have the right to hold it;
                </p>
                <p>
                  (e) object to your personal data being processed for a
                  particular purpose or to request that we stop using your
                  information;
                </p>
                <p>
                  (f) request not to be subject to a decision based on automated
                  processing and to have safeguards put in place if you are
                  being profiled based on your personal data;
                </p>
                <p>
                  (g) ask us to transfer a copy of your personal data to you or
                  to another service provider or third party where technically
                  feasible; and
                </p>
                <p>
                  (h) ask us to stop or start sending you marketing messages at
                  any time by using the below contact details.
                </p>
              </li>
              <li>
                <p>
                  Requests to exercise these rights should be made in writing
                  to&nbsp;
                  <a href="mailto:hello@zero-1.co">hello@zero-1.co</a>
                  &nbsp;we will respond within a within a reasonable period or
                  in accordance with any statutory time limits contained in Data
                  Protection Legislation. Some of these rights are not absolute
                  and will be assessed when we receive a request.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Opt-Out</h2>
            <ol>
              <li>
                <p>
                  You always have the option of &ldquo;unsubscribing&rdquo; from
                  our mailing list for email news alerts and updates at any
                  time, thereby disabling any further such email communication
                  from being sent to you, by clicking the link at the bottom of
                  the email.
                </p>
              </li>
              <li>
                <p>
                  Alternatively, you can send an email to&nbsp;
                  <a href="mailto:hello@zero-1.co">hello@zero-1.co</a>
                  &nbsp;with the word &lsquo;remove&rsquo; in the subject line
                  and the email address that you wish to be removed within the
                  email. In addition, each electronic mailing we send you will
                  contain details of how you can unsubscribe.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Automated processing</h2>
            <ol>
              <li>
                <p>
                  We do not carry out automated decision-making or profiling in
                  relation to your personal data.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Third party websites and services</h2>
            <ol>
              <li>
                <p>
                  Zero 1 may, from time to time, employ the services of other
                  parties for dealing with certain processes necessary for the
                  operation of the Website. The providers of such services have
                  access to certain personal data provided by Users of this
                  Website.
                </p>
              </li>
              <li>
                <p>
                  Any personal data used by such parties is used only to the
                  extent required by them to perform the services that we
                  request. Any use for other purposes is strictly prohibited.
                  Furthermore, any personal data that is processed by third
                  parties will be processed within the terms of this privacy
                  policy and in accordance with Data Protection Legislation.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Links to other websites</h2>
            <ol>
              <li>
                <p>
                  This Website may, from time to time, provide links to other
                  websites. We have no control over such websites and are not
                  responsible for the content of these websites. This privacy
                  policy does not extend to your use of such websites. You are
                  advised to read the privacy policy or statement of other
                  websites prior to using them.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Security</h2>
            <ol>
              <li>
                <p>
                  Data security is of great importance to Zero 1 and to protect
                  your personal data we have put in place suitable physical,
                  electronic and managerial procedures to safeguard and secure
                  your personal data.
                </p>
              </li>
              <li>
                <p>
                  If password access is required for certain parts of the
                  Website, you are responsible for keeping this password
                  confidential.
                </p>
              </li>
              <li>
                <p>
                  We endeavour to do our best to protect your personal data.
                  However, transmission of information over the internet is not
                  entirely secure and is done at your own risk. We cannot ensure
                  the security of your personal data transmitted to the Website.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Cookies</h2>
            <ol>
              <li>
                <p>
                  This Website may place and access certain Cookies on your
                  computer. We use Cookies to improve your experience of using
                  the Website and to improve our range of services. Zero 1 has
                  carefully chosen these Cookies and has taken steps to ensure
                  that your privacy is protected and respected at all times.
                </p>
              </li>
              <li>
                <p>
                  All Cookies used by this Website are used in accordance with
                  current UK and EU Cookie Law.
                </p>
              </li>
              <li>
                <p>
                  Before the Website places Cookies on your computer, you will
                  be presented with a pop-up requesting your consent to set
                  those Cookies. By giving your consent to the placing of
                  Cookies, you are enabling Zero 1 to provide a better
                  experience and service to you. You may, if you wish, deny
                  consent to the placing of Cookies; however certain features of
                  the Website may not function fully or as intended.
                </p>
              </li>
              <li>
                <p>This Website may place the following Cookies:</p>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>Type of Cookie</p>
                      </td>
                      <td>
                        <p>Purpose</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Strictly necessary cookies</p>
                      </td>
                      <td>
                        <p>
                          These are cookies that are required for the operation
                          of our Website. They include, for example, cookies
                          that enable you to log into secure areas of our
                          website, use a shopping cart or make use of e-billing
                          services.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Analytical/performance cookies</p>
                      </td>
                      <td>
                        <p>
                          They allow us to recognise and count the number of
                          visitors and to see how visitors move around our
                          Website when they are using it. This helps us to
                          improve the way our Website works, for example, by
                          ensuring that users are finding what they are looking
                          for easily.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Functionality cookies</p>
                      </td>
                      <td>
                        <p>
                          These are sued to recognise you when you return to our
                          website. This enables us to personalise our content
                          for you, greet you by name and remember your
                          preferences (for example, your choice of language or
                          region).
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Targeting cookies</p>
                      </td>
                      <td>
                        <p>
                          These cookies record your visit to our Website, the
                          pages you have visited and the links you have
                          followed. We will use this information to make our
                          Website and the advertising displayed on it more
                          relevant to your interests. We may also share this
                          information with third parties for this purpose.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li>
                <p>
                  You can choose to enable or disable Cookies in your internet
                  browser. By default, most internet browsers accept Cookies but
                  this can be changed. For further details, please consult the
                  help menu in your internet browser.
                </p>
              </li>
              <li>
                <p>
                  You can choose to delete Cookies at any time; however you may
                  lose any information that enables you to access the Website
                  more quickly and efficiently including, but not limited to,
                  personalisation settings.
                </p>
              </li>
              <li>
                <p>
                  It is recommended that you ensure that your internet browser
                  is up-to-date and that you consult the help and guidance
                  provided by the developer of your internet browser if you are
                  unsure about adjusting your privacy settings.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>General</h2>
            <ol>
              <li>
                <p>
                  You may not transfer any of your rights under this privacy
                  policy to any other person. We may transfer our rights under
                  this privacy policy where we reasonably believe your rights
                  will not be adversely affected.
                </p>
              </li>
              <li>
                <p>
                  If any court or competent authority finds that any provision
                  of this privacy policy (or part of any provision) is invalid,
                  illegal or unenforceable, that provision or part-provision
                  will, to the extent required, be deemed to be deleted, and the
                  validity and enforceability of the other provisions of this
                  privacy policy will not be affected.
                </p>
              </li>
              <li>
                <p>
                  Unless otherwise agreed, no delay, act or omission by a party
                  in exercising any right or remedy will be deemed a waiver of
                  that, or any other right or remedy.
                </p>
              </li>
              <li>
                <p>
                  This privacy policy is governed by and interpreted according
                  to English law. All disputes arising under this privacy policy
                  are subject to the exclusive jurisdiction of the English
                  courts.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Changes to this privacy policy</h2>
            <ol>
              <li>
                <p>
                  We may change this privacy policy as we may deem necessary
                  from time to time or as may be required by law. Any changes
                  will be immediately posted on the Website.
                </p>
              </li>
              <li>
                <p>
                  You are deemed to have accepted the terms of the privacy
                  policy by your use of the Website following the alterations.
                  If you do not accept the changes made to this privacy policy
                  you should immediately stop using the Website.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Contacting us and complaints</h2>
            <ol>
              <li>
                <p>
                  If you have any questions about this privacy policy you may
                  contact by email at{' '}
                  <a href="mailto:hello@zero-1.co">hello@zero-1.co</a>.
                </p>
              </li>
              <li>
                <p>
                  If you have any concerns about our use of your information,
                  you also have the right to make a complaint to the Information
                  Commissioner&rsquo;s Office, which regulates and supervises
                  the use of personal data in the UK, via their helpline on 0303
                  123 1113.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </LegalContent>
    </Layout>
  );
};

export default PrivacyPolicy;
